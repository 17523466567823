import { type Dispatch, type SetStateAction, createContext, useState, useContext } from "react";
import LoadingSpinner from "~/components/LoadingSpinner";

interface IsLoadingData {
  isLoading: boolean;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
}

const IsLoadingContext = createContext<IsLoadingData | null>(null);

function IsLoadingProvider({ children }: { children: JSX.Element | JSX.Element[] }) {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  return (
    <IsLoadingContext.Provider
      value={{
        isLoading,
        setIsLoading,
      }}
    >
      {children}
      <LoadingSpinner loading={isLoading} />
    </IsLoadingContext.Provider>
  );
}

function useIsLoading() {
  const context = useContext(IsLoadingContext);

  if (!context) {
    throw new Error("useIsLoading has been used out of scope.");
  }

  return context;
}

export { IsLoadingProvider, useIsLoading };
